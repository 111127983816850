import { HttpClient } from '@angular/common/http';
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
	id?: number;
	border?: boolean;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	apiBaseURL = environment.apiBaseURL;
	constructor(private http: HttpClient) {
		this.menuItems = [
			{
				title: 'gemstones', megaMenu: true, type: 'sub', active: false, children: [
					{ title: 'PRECIOUS GEMSTONES', type: 'sub', active: false, children: [], id: 1, border: true },
					{ title: 'SEMI-PRECIOUS GEMSTONES', type: 'sub', active: false, children: [], id: 2 },
					{ title: '', type: 'sub', active: false, children: [], id: 2, border: true },
					{ title: 'EXCLUSIVE  GEMSTONE COLLECTIONS', type: 'sub', active: false, children: [], id: 3,},
				]
			},
			{
				title: 'rudraksha', megaMenu: true, type: 'sub', active: false, children: [
					{ title: '', type: 'sub', active: false, children: [], id: 4 },
					{ title: '', type: 'sub', active: false, children: [], id: 4 },
				]
			},
			{
				title: 'Mala & Bracelet',  megaMenu: true, type: 'sub', active: false, children: [
					{ title: 'BRACELET 8MM', type: 'sub', active: false, children: [], id: 5 },
					{ title: '', type: 'sub', active: false, children: [], id: 5, border: true },
					{ title: 'MALA', type: 'sub', active: false, children: [], id: 8 },
					{ title: '', type: 'sub', active: false, children: [], id: 8 }
				]
			},
			// {
			// 	title: 'gem consultant', active: false, type: 'link', path: '/pages/gem-consultant', badge: true, badgeText: 'free'
			// },
			// {
			// 	title: 'jewellery', type: 'sub', active: false, children: [], id: 6
			// },
			{
				title: 'Healing Stones', type: 'sub', active: false, children: [], id: 7
			},
		];

		// this.getmenu().subscribe(res=>{	
		// 	if(res && res.data) {
		// 		let catList = res.data;
		// 		this.menuItems[0].children.forEach(m=>{
		// 			catList.filter(x=>x.category_type_id === m.id).forEach(x => {
		// 				let item: Menu = {
		// 					path:'', title: x.name, type: 'link', 'image': x.image_link
		// 				}

		// 				m.children.push(item);
		// 			});
		// 		})
		// 	}		
		// })
	}

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;
	public menuItems: Menu[] = [];

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	public getmenu(): Observable<any> {
		return this.http.get(this.apiBaseURL + 'category/listall');
	}


	MENUITEMS: Menu[] = [
		{
			title: 'gemstones', megaMenu: true, type: 'sub', active: false, children: [
				{
					title: 'zodiac (rashi)', type: 'sub', active: false, children: [
						{ path: '/pages/comingsoon', title: 'Ruby (Manik)', type: 'link', image: './assets/images/product/menu/ruby.webp' },
						{ path: '/pages/comingsoon', title: 'Pearl (Moti)', type: 'link', image: './assets/images/product/menu/pearl.webp' },
						{ path: '/pages/comingsoon', title: 'Cats Eye', type: 'link', image: './assets/images/product/menu/cats-eye.webp' },
						{ path: '/pages/comingsoon', title: 'Yellow Sapphire', type: 'link', image: './assets/images/product/menu/yellow-sapphire.webp' },
						{ path: '/pages/comingsoon', title: 'White Sapphire', type: 'link', image: './assets/images/product/menu/white-sapphire.webp' }
					],
				},
				{
					title: 'precious (ratna)', type: 'sub', active: false, children: [
						{ path: '/pages/comingsoon', title: 'Emerald (Panna)', type: 'link', image: './assets/images/product/menu/emerald-panna.webp' },
						{ path: '/pages/comingsoon', title: 'Purple Sapphire', type: 'link', image: './assets/images/product/menu/purple-sapphire.jpg' },
						{ path: '/pages/comingsoon', title: 'Bi-Color Sapphire', type: 'link', image: './assets/images/product/menu/bi-color.jpg' },
						{ path: '/pages/comingsoon', title: 'Navratna', type: 'link', image: './assets/images/product/menu/navratna.webp' },
					],
				},
				{
					title: 'semi precious (upratna)', type: 'sub', active: false, children: [
						{ path: '/pages/comingsoon', title: 'Star Ruby', type: 'link', image: './assets/images/product/menu/star-ruby.webp' },
						{ path: '/pages/comingsoon', title: 'Moonstone', type: 'link', image: './assets/images/product/menu/moonstone.webp' },
						{ path: '/pages/comingsoon', title: 'Spinel', type: 'link', image: './assets/images/product/menu/spinel.webp' },
						{ path: '/pages/comingsoon', title: 'Ametrine', type: 'link', image: './assets/images/product/menu/ametrine.webp' }
					],
				}
			]
		},
		{
			title: 'rudraksha', megaMenu: true, type: 'sub', active: false, children: [{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: '1 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/1.jpg' },
					{ path: '/pages/comingsoon', title: '2 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/2.jpg' },
					{ path: '/pages/comingsoon', title: '3 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/3.jpg' },
					{ path: '/pages/comingsoon', title: '4 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/4.jpg' },
					{ path: '/pages/comingsoon', title: '5 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/5.jpg' },
					{ path: '/pages/comingsoon', title: '6 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/6.jpg' },
				]
			},
			{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: '7 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/7.jpg' },
					{ path: '/pages/comingsoon', title: '8 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/8.jpg' },
					{ path: '/pages/comingsoon', title: '9 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/9.jpg' },
					{ path: '/pages/comingsoon', title: '10 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/10.jpg' },
					{ path: '/pages/comingsoon', title: '11 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/11.jpg' },
					{ path: '/pages/comingsoon', title: '12 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/12.jpg' },
				]
			},
			{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: '13 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/13.jpg' },
					{ path: '/pages/comingsoon', title: '14 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/14.jpg' },
					{ path: '/pages/comingsoon', title: '15 rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/15.jpg' },
					{ path: '/pages/comingsoon', title: 'rudraksha mala', type: 'link', image: './assets/images/product/menu/rudraksha/mala.jpg' },
					{ path: '/pages/comingsoon', title: 'Gauri Shankar Rudraksha', type: 'link', image: './assets/images/product/menu/rudraksha/gaurishankar.jpg' }
				]
			}
			]
		},
		{
			title: 'Healing Stones', megaMenu: true, type: 'sub', active: false, children: [{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: 'agte black (sulemani hakik)', type: 'link', image: './assets/images/product/menu/healingstones/agate_black_sulemani_hakik.png' },
					{ path: '/pages/comingsoon', title: "black tourmaline", type: 'link', image: './assets/images/product/menu/healingstones/black_tourmaline.png' },
					{ path: '/pages/comingsoon', title: 'chrysocolla', type: 'link', image: './assets/images/product/menu/healingstones/chrysocolla.png' },
					{ path: '/pages/comingsoon', title: 'labradorite', type: 'link', image: './assets/images/product/menu/healingstones/labradorite.png' },
					{ path: '/pages/comingsoon', title: 'obsidian', type: 'link', image: './assets/images/product/menu/healingstones/obsidian.png' },
					{ path: '/pages/comingsoon', title: 'rose quartz', type: 'link', image: './assets/images/product/menu/healingstones/rose_quartz.png' }
				]
			},
			{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: 'agate red (sulemani hakik)', type: 'link', image: './assets/images/product/menu/healingstones/agate_red_sulemani_hakik.png' },
					{ path: '/pages/comingsoon', title: 'bloodstone', type: 'link', image: './assets/images/product/menu/healingstones/bloodstone.png' },
					{ path: '/pages/comingsoon', title: 'himalayan quartz crystal', type: 'link', image: './assets/images/product/menu/healingstones/himalayan_quartz_crystal.png' },
					{ path: '/pages/comingsoon', title: 'lapis lazuli (lajward)', type: 'link', image: './assets/images/product/menu/healingstones/lapis_lazuli_lajward.png' },
					{ path: '/pages/comingsoon', title: 'onyx', type: 'link', image: './assets/images/product/menu/healingstones/onyx.png' },
					{ path: '/pages/comingsoon', title: 'rutilated quartz', type: 'link', image: './assets/images/product/menu/healingstones/rutilated_quartz.png' },
				]
			},
			{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: 'aventurine quartz', type: 'link', image: './assets/images/product/menu/healingstones/aventurine_quartz.png' },
					{ path: '/pages/comingsoon', title: 'carnelian', type: 'link', image: './assets/images/product/menu/healingstones/aventurine_quartz.png' },
					{ path: '/pages/comingsoon', title: 'jade', type: 'link', image: './assets/images/product/menu/healingstones/jade.png' },
					{ path: '/pages/comingsoon', title: 'malachite', type: 'link', image: './assets/images/product/menu/healingstones/malachite.png' },
					{ path: '/pages/comingsoon', title: 'prehnite', type: 'link', image: './assets/images/product/menu/healingstones/prehnite.png' },
					{ path: '/pages/comingsoon', title: 'sunstone', type: 'link', image: './assets/images/product/menu/healingstones/sunstone.png' }
				]
			},
			{
				title: '', type: 'sub', active: false, children: [
					{ path: '/pages/comingsoon', title: 'azurite', type: 'link', image: './assets/images/product/menu/healingstones/azurite.png' },
					{ path: '/pages/comingsoon', title: 'chalcedony', type: 'link', image: './assets/images/product/menu/healingstones/chalcedony.png' },
					{ path: '/pages/comingsoon', title: 'jasper', type: 'link', image: './assets/images/product/menu/healingstones/jasper.png' },
					{ path: '/pages/comingsoon', title: 'moldavite', type: 'link', image: './assets/images/product/menu/healingstones/moldavite.png' },
					{ path: '/pages/comingsoon', title: 'rhodochrosite', type: 'link', image: './assets/images/product/menu/healingstones/rhodochrosite.png' },
					{ path: '/pages/comingsoon', title: "tiger's eye", type: 'link', image: './assets/images/product/menu/healingstones/tigers_eye.png' }
				]
			}
			]
		},
		{
			title: 'beads & bracelets', type: 'sub', active: false, children: [
				{ path: '/pages/comingsoon', title: 'healing bracelet', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'japa mala (rosary)', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'loose beads string', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'customised bracelet', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'tasbih beads', type: 'link', image: '' }
			]
		},
		{
			title: 'jewellery', type: 'sub', active: false, children: [
				{ path: '/pages/comingsoon', title: 'Healing Pendants', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'Earing & Studs', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'Navratna Jewellery', type: 'link', image: '' },
				{ path: '/pages/comingsoon', title: 'Men Accessories', type: 'link', image: '' }
			]
		},
		{
			title: 'gem recommendation', active: false, type: 'link', path: '/pages/comingsoon', badge: true, badgeText: 'free'
		}
		// {
		// 	title: 'about us', active: false, type: 'link', path: '/pages/comingsoon'
		// },
		// {
		// 	title: 'contact us', active: false, type: 'link', path: '/pages/comingsoon'
		// },
		// {
		// 	title: 'home', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'clothing', type: 'sub', active: false, children: [
		// 				{ path: '/home/fashion', title: 'fashion-01', type: 'link' },
		// 				{ path: '/home/fashion-2', title: 'fashion-02', type: 'link' },
		// 				{ path: '/home/fashion-3', title: 'fashion-03', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/home/vegetable', title: 'vegetable', type: 'link' },
		// 		{ path: '/home/watch', title: 'watch', type: 'link' },
		// 		{ path: '/home/furniture', title: 'furniture', type: 'link' },
		// 		{ path: '/home/flower', title: 'flower', type: 'link' },
		// 		{ path: '/home/beauty', title: 'beauty', type: 'link' },
		// 		{ path: '/home/electronics', title: 'electronics', type: 'link' },
		// 		{ path: '/home/pets', title: 'pets', type: 'link' },
		// 		{ path: '/home/gym', title: 'gym', type: 'link' },
		// 		{ path: '/home/tools', title: 'tools', type: 'link' },
		// 		{ path: '/home/shoes', title: 'shoes', type: 'link' },
		// 		{ path: '/home/bags', title: 'bags', type: 'link' },
		// 		{ path: '/home/marijuana', title: 'marijuana', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Shop', type: 'sub', active: false, children: [
		// 		{ path: '/shop/collection/left/sidebar', title: 'left-sidebar', type: 'link' },
		// 		{ path: '/shop/collection/right/sidebar', title: 'right-sidebar', type: 'link' },
		// 		{ path: '/shop/collection/no/sidebar', title: 'no-sidebar', type: 'link' },
		// 		{ path: '/shop/collection/infinitescroll', title: 'Infinite Scroll', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Products', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'sidebar', type: 'sub', active: false, children: [
		// 				{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
		// 				{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
		// 				{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
		// 			]
		// 		},
		// 		{ path: '/shop/product/three/column/trim-dress', title: 'three-column', type: 'link' },
		// 		{ path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
		// 		{ path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
		// 		{ path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Features', type: 'sub', megaMenu: true, badge: true, badgeText: 'new', active: false, children: [
		// 		{
		// 			title: 'portfolio', type: 'sub', active: false, children: [
		// 				{ path: '/pages/portfolio/grid/two', title: 'portfolio-grid-2', type: 'link' },
		// 				{ path: '/pages/portfolio/grid/three', title: 'portfolio-grid-3', type: 'link' },
		// 				{ path: '/pages/portfolio/grid/four', title: 'portfolio-grid-4', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/grid/two', title: 'masonry-grid-2', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/grid/three', title: 'masonry-grid-3', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/grid/four', title: 'masonry-grid-4', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/full-width', title: 'masonry-Full-Width', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'add-to-cart', type: 'sub', active: false, children: [
		// 				{ path: '/home/vegetable', title: 'cart-right', type: 'link' },
		// 				{ path: '/home/watch', title: 'cart-left', type: 'link' },
		// 				{ path: '/home/furniture', title: 'cart-top', type: 'link' },
		// 				{ path: '/home/flower', title: 'cart-bottom', type: 'link' },
		// 				{ path: '/home/fashion', title: 'cart-model-popup', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'theme-elements', type: 'sub', active: false, children: [
		// 				{ path: '/elements/theme/title', title: 'title', type: 'link' },
		// 				{ path: '/elements/theme/collection-banner', title: 'collection-banner', type: 'link' },
		// 				{ path: '/elements/theme/home-slider', title: 'home-slider', type: 'link' },
		// 				{ path: '/elements/theme/category', title: 'category', type: 'link' },
		// 				{ path: '/elements/theme/services', title: 'services', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'product-elements', type: 'sub', active: false, children: [
		// 				{ path: '/elements/product/slider', title: 'product-slider', type: 'link' },
		// 				{ path: '/elements/product/banners', title: 'banners', type: 'link' },
		// 				{ path: '/elements/product/tabs', title: 'product-tabs', type: 'link' },
		// 				{ path: '/elements/product/multi-slider', title: 'multi-slider', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'email-template', type: 'sub', active: false, children: [
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-order-success.html', title: 'order-success', type: 'extTabLink' },
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-order-success-two.html', title: 'order-success-2', type: 'extTabLink' },
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-template.html', title: 'email-template', type: 'extTabLink' },
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-template-two.html', title: 'email-template-2', type: 'extTabLink' }
		// 			]
		// 		}
		// 	]
		// },
		// {
		// 	title: 'pages', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'account', type: 'sub', active: false, children: [
		// 				{ path: '/pages/wishlist', title: 'wishlist', type: 'link' },
		// 				{ path: '/pages/cart', title: 'cart', type: 'link' },
		// 				{ path: '/pages/dashboard', title: 'dashboard', type: 'link' },
		// 				{ path: '/pages/login', title: 'login', type: 'link' },
		// 				{ path: '/pages/register', title: 'register', type: 'link' },
		// 				{ path: '/pages/contact', title: 'contact', type: 'link' },
		// 				{ path: '/pages/forget/password', title: 'forget-password', type: 'link' },
		// 				{ path: '/pages/profile', title: 'profile', type: 'link' },
		// 				{ path: '/pages/checkout', title: 'checkout', type: 'link' },
		// 			]
		// 		},
		// 		{ path: '/pages/aboutus', title: 'about-us', type: 'link' },
		// 		{ path: '/pages/search', title: 'search', type: 'link' },
		// 		{ path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
		// 		{ path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
		// 		{ path: '/pages/order/success', title: 'order-success', type: 'link' },
		// 			{ 
		// 				title: 'compare', type: 'sub', active: false, children: [
		// 					{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
		// 					{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
		// 				]
		// 			},
		// 		{ path: '/pages/collection', title: 'collection', type: 'link' },
		// 		{ path: '/pages/lookbook', title: 'lookbook', type: 'link' },
		// 		{ path: '/pages/404', title: '404', type: 'link' },
		// 		{ path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
		// 		{ path: '/pages/faq', title: 'faq', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'blogs', type: 'sub', active: false, children: [
		// 		{ path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
		// 		{ path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
		// 		{ path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
		// 		{ path: '/pages/blog/details', title: 'blog-details', type: 'link' }
		// 	]
		// }
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
				{
					title: 'mens fashion', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'top', type: 'link' },
						{ path: '/home/fashion', title: 'bottom', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'shirts', type: 'link' },
						{ path: '/home/fashion', title: 'bottom', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' }
					]
				},
				{
					title: 'women fashion', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'dresses', type: 'link' },
						{ path: '/home/fashion', title: 'skirts', type: 'link' },
						{ path: '/home/fashion', title: 'westarn wear', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'bottom', type: 'link' },
						{ path: '/home/fashion', title: 'ethic wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'sports wear', type: 'link' },
						{ path: '/home/fashion', title: 'bottom wear', type: 'link' }
					]
				},
			]
		},
		{
			title: 'bags', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'shopper bags', type: 'link' },
				{ path: '/home/fashion', title: 'laptop bags', type: 'link' },
				{ path: '/home/fashion', title: 'clutches', type: 'link' },
				{
					path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'purses', type: 'link' },
						{ path: '/home/fashion', title: 'wallets', type: 'link' },
						{ path: '/home/fashion', title: 'leathers', type: 'link' },
						{ path: '/home/fashion', title: 'satchels', type: 'link' }
					]
				},
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'sport shoes', type: 'link' },
				{ path: '/home/fashion', title: 'formal shoes', type: 'link' },
				{ path: '/home/fashion', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
				{ path: '/home/fashion', title: 'caps and hats', type: 'link' },
				{ path: '/home/fashion', title: 'precious jewellery', type: 'link' },
				{
					path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'necklaces', type: 'link' },
						{ path: '/home/fashion', title: 'earrings', type: 'link' },
						{ path: '/home/fashion', title: 'rings & wrist wear', type: 'link' },
						{
							path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
								{ path: '/home/fashion', title: 'ties', type: 'link' },
								{ path: '/home/fashion', title: 'cufflinks', type: 'link' },
								{ path: '/home/fashion', title: 'pockets squares', type: 'link' },
								{ path: '/home/fashion', title: 'helmets', type: 'link' },
								{ path: '/home/fashion', title: 'scarves', type: 'link' },
								{
									path: '/home/fashion', title: 'more...', type: 'link', active: false, children: [
										{ path: '/home/fashion', title: 'accessory gift sets', type: 'link' },
										{ path: '/home/fashion', title: 'travel accessories', type: 'link' },
										{ path: '/home/fashion', title: 'phone cases', type: 'link' }
									]
								},
							]
						}
					]
				},
			]
		},
		{
			path: '/home/fashion', title: 'house of design', type: 'link'
		},
		{
			title: 'beauty & personal care', type: 'sub', active: false, children: [
				{ path: '/home/fashion', title: 'makeup', type: 'link' },
				{ path: '/home/fashion', title: 'skincare', type: 'link' },
				{ path: '/home/fashion', title: 'premium beaty', type: 'link' },
				{
					path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'fragrances', type: 'link' },
						{ path: '/home/fashion', title: 'luxury beauty', type: 'link' },
						{ path: '/home/fashion', title: 'hair care', type: 'link' },
						{ path: '/home/fashion', title: 'tools & brushes', type: 'link' }
					]
				},
			]
		},
		{
			path: '/home/fashion', title: 'home & decor', type: 'link'
		},
		{
			path: '/home/fashion', title: 'kitchen', type: 'link'
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.menuItems);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
