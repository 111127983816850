import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  apiBaseURL = environment.apiBaseURL;

  constructor(private http: HttpClient,
    private toastrService: ToastrService) { }

  public signup(data) {
    return this.http.post(this.apiBaseURL + 'customer/signup', data);
  }

  public signin(data) {
    return this.http.post(this.apiBaseURL + 'customer/signin', data);
  }

  public verifyOtp(data) {
    return this.http.post(this.apiBaseURL + 'customer/verifyotp', data);
  }

  public signinViaMobile(data) {
    return this.http.post(this.apiBaseURL + 'customer/signinwithmobileno', data);
  }

  public googleSignIn(data) {
    return this.http.post(this.apiBaseURL + 'customer/googlesignin', data);
  }

}
