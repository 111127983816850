<ng-template #signupModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <a type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <img src="./assets/images/icon/cross.svg" width="12px">
            </a>
            <div class="row">
                <div class="col-lg-5  col-xs-12 login-modal-left">
                    <div>
                        <h3>Sign Up</h3>
                        <h6>Get access to your Orders, Wishlist and Recommendations</h6>
                    </div>
                    <div>
                        <h6 class="mb-3">Already have an account?<br /><a class="btn btn-outline" (click)="openLoginModal()">Sign In</a></h6>

                    </div>
                </div>
                <div class="col-lg-7  col-xs-12 login-modal-right">
                    <h4>Welcome</h4>
                    <!-- <h6 class="mb-4">Sign into your account</h6> -->
                    <div class="theme-card">
                        <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="submitLogin()" *ngIf="isShowSignup">
                            <div class="form-group mb-4">
                                <input type="text" formControlName="name" class="form-control"
                                    placeholder="Enter your name">

                                <div *ngIf="isSubmitted && name.invalid && (name.dirty || name.touched)" class="error-text">
                                    <div *ngIf="name.errors.required">
                                        Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <input type="email" formControlName="email" class="form-control"
                                    placeholder="Enter your Email id">
                                <div *ngIf="isSubmitted && email.invalid && (email.dirty || email.touched)" class="error-text">
                                    <div *ngIf="email.errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="email.errors.email">
                                        Invalid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="mobile_no">+91</span>
                                    <input type="number" formControlName="mobile_no" class="form-control" id="mobile_no"
                                        placeholder="Enter your mobile number">
                                </div>
                                <div *ngIf="isSubmitted && mobile_no.invalid && (mobile_no.dirty || mobile_no.touched)"
                                        class="error-text">
                                        <div *ngIf="mobile_no.errors.required">
                                            Mobile Number is required.
                                        </div>
                                        <div *ngIf="mobile_no.errors.pattern">
                                            Invalid Mobile Number.
                                        </div>
                                    </div>
                            </div>
                            <div class="form-group mb-4">
                                <input type="password" formControlName="password" class="form-control"
                                    placeholder="Enter your password">
                                <div *ngIf="isSubmitted && password.invalid && (mobile_no.dirty || mobile_no.touched)"
                                    class="error-text">
                                    <div *ngIf="password.errors.required">
                                        Password is required.
                                    </div>
                                    <div *ngIf="password.errors.pattern">
                                        Password must be at least 8 characters. Password must have at least one non letter or digit character, one digit ('0'-'9') and one uppercase ('A'-'Z').
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <button type="submit" class="btn btn-primary">Create Account</button>
                            </div>

                            <div class="form-group mb-4">
                                <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin>
                            </div>
                            
                            <!-- <div class="form-group mb-4">
                                <p>Forgot Password?<a class="forgot-password-link"> Click here</a></p>
                            </div> -->
                            <p class="error-text" *ngIf="errorMsg">{{errorMsg}}</p>
                        </form>
                        <form class="theme-form" *ngIf="isShowOTP">
                            <p>Verification code has been sent to your registered mobile no.</p>
                            <div class="form-group mb-4">
                                <input type="number" class="form-control" placeholder="Enter OTP" [ngModelOptions]="{standalone: true}" [(ngModel)]="code">
                            </div>
                            <div class="form-group mb-4">
                                <button type="submit" class="btn btn-primary" (click)="verifyOTP()">Verify OTP</button>                                
                            </div>
                            <p class="error-text" *ngIf="errorMsg">{{errorMsg}}</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
