<!-- whatsapp icon -->
<div class="tap-whatsapp top-cls" >
    <a href="https://api.whatsapp.com/send?phone=916364671972" target="_blank">      
      <i class="fa fa-whatsapp" aria-hidden="true"></i>
    </a>
</div>

<div class="tap-whatsapp top-phone" >
  <a href="tel:+916364671972" target="_blank">      
    <i class="fa fa-phone" aria-hidden="true"></i>
  </a>
</div>

<div class="tap-whatsapp top-chat" >
  <a routerLink="/pages/contact">      
    <i class="fa fa-comments" aria-hidden="true"></i>
  </a>
</div>