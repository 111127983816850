<ng-template #videoModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <a type="button" class="close float-end border-0" style="padding-right: 5px;" (click)="modal.dismiss('Cross click')">
                <img src="./assets/images/icon/cross.svg" width="12px">
            </a>
            <iframe *ngIf="videoUrl" [src]="videoUrl" allowfullscreen></iframe>
        </div>
    </div>
</ng-template>
