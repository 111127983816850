import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService) {
    this.menuItems = this.navServices.menuItems;

    this.navServices.getmenu().subscribe(res => {
      if (res && res.data) {
        let catList = res.data;
        this.menuItems[0].children.forEach((m, idx) => {
          if (idx !== 2) {
            m.children = [];
          }
          if (m.id !== 2) {
            catList.filter(x => x.category_type_id === m.id).forEach(x => {
              let item: Menu = {
                path: '/gems/category/' + x.name, title: x.name, type: 'link', 'image': x.icon_link
              }
              m.children.push(item);
            });
          } else if (m.id == 2 && idx == 1) {
            this.menuItems[0].children[2].children = [];
            let mlength = Math.ceil(catList.filter(x => x.category_type_id === 2).length / 2);
            if (mlength < 1) {
              mlength = 1;
            }
            catList.filter(x => x.category_type_id === 2).forEach((x, idx) => {
              let item: Menu = {
                path: '/gems/category/' + x.name, title: x.name, type: 'link', 'image': x.icon_link
              }

              if (mlength > idx) {
                m.children.push(item);
              } else {
                this.menuItems[0].children[2].children.push(item);
              }

            });

          }
        })


        this.menuItems[1].children[0].children = [];
        this.menuItems[1].children[1].children = [];
        let mlen = Math.ceil(catList.filter(x => x.category_type_id === 2).length / 2);
        if (mlen < 1) {
          mlen = 1;
        }
        catList.filter(x => x.category_type_id === 4).forEach((x, idx) => {
          let item: Menu = {
            path: '/gems/category/' + x.name, title: x.name, type: 'link', 'image': x.icon_link
          }

          if (mlen > idx) {
            this.menuItems[1].children[0].children.push(item);
          } else {
            this.menuItems[1].children[1].children.push(item);
          }

        });


        this.menuItems[2].children[0].children = [];
        this.menuItems[2].children[1].children = [];
        this.menuItems[2].children[2].children = [];
        this.menuItems[2].children[3].children = [];
        let mlen5 = Math.ceil(catList.filter(x => x.category_type_id === 5).length / 2);
        if (mlen5 < 1) {
          mlen5 = 1;
        }
        catList.filter(x => x.category_type_id === 5).forEach((x, idx) => {
          let item: Menu = {
            path: '/gems/category/' + x.name, title: x.name, type: 'link', 'image': x.icon_link
          }

          if (mlen5 > idx) {
            this.menuItems[2].children[0].children.push(item);
          } else {
            this.menuItems[2].children[1].children.push(item);
          }
        });
        let mlen8 = Math.ceil(catList.filter(x => x.category_type_id === 8).length / 2);
        if (mlen8 < 1) {
          mlen8 = 1;
        }
        catList.filter(x => x.category_type_id === 8).forEach((x, idx) => {
          let item: Menu = {
            path: '/gems/category/' + x.name, title: x.name, type: 'link', 'image': x.icon_link
          }

          if (mlen8 > idx) {
            this.menuItems[2].children[2].children.push(item);
          } else {
            this.menuItems[2].children[3].children.push(item);
          }
        });


        for (let i = 3; i < this.menuItems.length; i++) {
          this.menuItems[i].children = [];
          catList.filter(x => x.category_type_id === this.menuItems[i].id).forEach(x => {
            let item: Menu = {
              path: '/gems/category/' + x.name, title: x.name, type: 'link', 'image': x.icon_link
            }
            this.menuItems[i].children.push(item);
          });
        }
      }
    });
    // this.navServices.items.subscribe(menuItems => { this.menuItems = menuItems; debugger; });
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
