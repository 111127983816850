import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrl: './signup-modal.component.scss'
})
export class SignupModalComponent implements OnInit, OnDestroy {
  @ViewChild("signupModal", { static: false }) SignupModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  loginForm: FormGroup;
  errorMsg;
  isSubmitted: boolean = false;
  isShowSignup: boolean = true;
  isShowOTP: boolean = false;
  customerInfo;
  code;

  @Output() showLoginModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal, private customerService: CustomerService, private cookie: CookieService, private dataService: DataService) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      mobile_no: new FormControl("", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      password: new FormControl("", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)])
    });

  }

  openModal() {
    this.modalService.dismissAll();
    this.isShowSignup = true;
    this.isShowOTP = false;
    this.modalOpen = true;
    this.isSubmitted = false;
    this.code = null;
    this.customerInfo = null;
    this.errorMsg = "";
    this.loginForm.reset();
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SignupModal, {
        size: 'lg',
        ariaLabelledBy: 'Signup-Modal',
        centered: true,
        windowClass: 'modal fade login-modal',
        backdrop: 'static',
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  submitLogin() {
    this.errorMsg = "";
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      let data = {
        name: this.name.value,
        email: this.email.value,
        mobile_no: this.mobile_no.value,
        password: this.password.value
      }
      this.customerService.signup(data).subscribe((res) => {
        debugger;
        if (res && res['status'] == 1) {
          if (res && res['data']) {
            this.isShowSignup = false;
            this.isShowOTP = true;
            this.customerInfo = res['data'];
          } else {
            this.errorMsg = "Invalid Mobile No. and Password";
          }
        } else {
          this.errorMsg = res['msg'];
        }
      }, error => {
        if (error && error['msg']) {
          this.errorMsg = error['msg'];
        }
      })
    } else {
      this.loginForm.markAllAsTouched();
    }

  }

  verifyOTP() {
    if (this.code) {
      let data = {
        code: this.code,
        is_verified: false,
        customer_id: this.customerInfo.id
      }
      this.customerService.verifyOtp(data).subscribe((res) => {
        if (res && res['status'] == 1) {
          this.cookie.set("_userinfo", JSON.stringify(this.customerInfo));
          this.dataService.updateUser(this.customerInfo);
          this.modalService.dismissAll();
        } else {
          this.errorMsg = res['msg'];
        }
      }, error => {
        if (error && error['msg']) {
          this.errorMsg = error['msg'];
        }
      })
    }
  }

  openLoginModal() {
    this.showLoginModal.emit(null);
  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }


  get name() {
    return this.loginForm.get('name');
  }

  get email() {
    return this.loginForm.get('email');
  }

  get mobile_no() {
    return this.loginForm.get('mobile_no');
  }

  get password() {
    return this.loginForm.get('password');
  }

  ngOnDestroy(): void {

  }
}
