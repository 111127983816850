import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { DataService } from 'src/app/shared/services/data.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss'
})
export class LoginModalComponent implements OnInit, OnDestroy {
  @ViewChild("loginModal", { static: false }) LoginModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  loginForm: FormGroup;
  errorMsg;
  isShowLogin: boolean = true;
  isShowMobileNo: boolean = false;
  isShowOTP: boolean = false;
  mobileNo: string;
  customerInfo;
  code;

  @Output() showSignupModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal, private customerService: CustomerService, private dataService: DataService, private cookie: CookieService) { }

  ngOnInit(): void {

    this.loginForm = new FormGroup({
      mobile_no: new FormControl("", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      password: new FormControl("", [Validators.required])
    });

  }

  openModal() {
    this.modalService.dismissAll();
    this.modalOpen = true;
    this.errorMsg = "";
    this.isShowLogin = true;
    this.isShowMobileNo = false;
    this.isShowOTP = false;
    this.customerInfo = null;
    this.loginForm.reset();
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.LoginModal, {
        size: 'lg',
        ariaLabelledBy: 'Login-Modal',
        centered: true,
        windowClass: 'modal fade login-modal',
        backdrop: 'static',
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  submitLogin() {
    this.errorMsg = "";
    if (this.loginForm.valid) {
      let data = {
        mobile_no: this.mobile_no.value,
        password: this.password.value
      }
      this.customerService.signin(data).subscribe((res) => {
        if (res && res['data'] && res['data'].length > 0) {
          this.customerInfo = res['data'][0];
          this.cookie.set("_userinfo", JSON.stringify(this.customerInfo));
          this.dataService.updateUser(this.customerInfo);
          this.modalService.dismissAll();
        } else {
          this.errorMsg = "The Mobile No. or Password you entered is incorrect. Please try again.";
        }
      }, error => {
        if (error && error['msg']) {
          this.errorMsg = error['msg'];
        }
      })
    }
  }

  openSignupModal() {
    this.showSignupModal.emit(null);
  }

  showModal(type) {
    this.errorMsg = "";
    this.mobileNo = "";
    this.code = "";
    if (type == 1) {
      this.mobileNo = "";
      this.isShowLogin = true;
      this.isShowMobileNo = false;
      this.isShowOTP = false;
    } else if (type == 2) {
      this.mobileNo = "";
      this.isShowLogin = false;
      this.isShowMobileNo = true;
      this.isShowOTP = false;
    } else if (type == 3) {
      this.isShowLogin = false;
      this.isShowMobileNo = false;
      this.isShowOTP = true;
    }
  }

  sendOTP() {
    if (this.mobileNo && this.mobileNo.toString().length == 10) {
      let data = {
        mobile_no: this.mobileNo
      }

      this.customerService.signinViaMobile(data).subscribe((res) => {
        if (res && res['status'] == 1) {
          if (res['data'] && res['data'].length > 0) {
            if (res['data'][0].is_active) {
              this.customerInfo = res['data'][0];
              this.showModal(3);
            } else {
              this.errorMsg = "Your account has been blocked. Please contact us";
            }
          } else {
            this.errorMsg = res['msg'];
          }
        }

      }, error => {
        if (error && error['msg']) {
          this.errorMsg = error['msg'];
        }
      })

    }
  }

  verifyOTP() {
    if (this.code) {
      let data = {
        code: this.code,
        is_verified: this.customerInfo.is_verified,
        customer_id: this.customerInfo.id
      }
      this.customerService.verifyOtp(data).subscribe((res) => {
        if (res && res['status'] == 1) {
          this.cookie.set("_userinfo", JSON.stringify(this.customerInfo));
          this.dataService.updateUser(this.customerInfo);
          this.modalService.dismissAll();
        } else {
          this.errorMsg = res['msg'];
        }
      }, error => {
        if (error && error['msg']) {
          this.errorMsg = error['msg'];
        }
      })
    }
  }

  googleSignin(response) {
    this.customerService.googleSignIn({ token: response.credential }).subscribe(res => {
      if (res && res['status'] == 1) {
        if (res['data']) {
          this.cookie.set("_userinfo", JSON.stringify(res['data']));
          this.dataService.updateUser(res['data']);
          this.modalService.dismissAll();
        }
      } else {
        if (res && res['msg']) {
          this.errorMsg = res['msg'];
        }
      }
    }, error => {
      if (error && error['msg']) {
        this.errorMsg = error['msg'];
      }
    })
  }

  get mobile_no() {
    return this.loginForm.get('mobile_no');
  }

  get password() {
    return this.loginForm.get('password');
  }

  ngOnDestroy(): void {

  }
}
