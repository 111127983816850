<ng-template #loginModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <a type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <img src="./assets/images/icon/cross.svg" width="12px">
            </a>
            <div class="row">
                <div class="col-lg-5  col-xs-12 login-modal-left">
                    <div>
                        <h3>Sign In</h3>
                        <h6>Get access to your Orders, Wishlist and Recommendations</h6>
                    </div>
                    <div>
                        <h6 class="mb-3">New Customer?<br /><a (click)="openSignupModal()"
                                class="btn btn-outline">Create Account</a></h6>

                    </div>
                </div>
                <div class="col-lg-7  col-xs-12 login-modal-right">
                    <h4>Welcome Back</h4>
                    <h6 class="mb-4">Sign into your account</h6>
                    <div class="theme-card">
                        <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="submitLogin()" *ngIf="isShowLogin">
                            <div class="form-group mb-4">
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="basic-addon1">+91</span>
                                    <input type="number" formControlName="mobile_no" class="form-control" id="mobile_no"
                                        placeholder="Enter your mobile number">
                                </div>
                            </div>

                            <div class="form-group mb-4">
                                <input type="password" formControlName="password" class="form-control" id="review"
                                    placeholder="Enter your password">
                            </div>
                            <div class="form-group mb-4">
                                <button type="submit" class="btn btn-primary">Sign In</button>
                                <button type="button" class="btn btn-secondary" (click)="showModal(2)">Login with OTP</button>
                            </div>
                            <div class="form-group mb-4 col-lg-6">
                                <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin>
                            </div>
                            <!-- <div class="form-group mb-4">
                                <p>Forgot Password?<a class="forgot-password-link"> Click here</a></p>
                            </div> -->
                            <p class="error-text" *ngIf="errorMsg">{{errorMsg}}</p>
                        </form>

                        <form class="theme-form" *ngIf="isShowMobileNo">
                            <div class="form-group mb-4">
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="basic-addon1">+91</span>
                                    <input type="number" [(ngModel)]="mobileNo" [ngModelOptions]="{standalone: true}" class="form-control" id="mobile_no"
                                        placeholder="Enter your mobile number">
                                </div>
                            </div>

                            <div class="form-group mb-4">
                                <button type="submit" class="btn btn-primary" (click)="sendOTP()">Send OTP</button>
                                <button type="button" class="btn btn-secondary" (click)="showModal(1)">Login with Password</button>
                            </div>
                            <p class="error-text" *ngIf="errorMsg">{{errorMsg}}</p>
                        </form>

                        <form class="theme-form" *ngIf="isShowOTP">
                            <p>Verification code has been sent to your registered mobile no.</p>
                            <div class="form-group mb-4">
                                <input type="number" class="form-control" [(ngModel)]="code" [ngModelOptions]="{standalone: true}" placeholder="Enter OTP">
                            </div>

                            <div class="form-group mb-4">
                                <button type="submit" class="btn btn-primary" (click)="verifyOTP()">Verify OTP</button>
                                <button type="button" class="btn btn-secondary" (click)="showModal(1)">Login with Password</button>
                            </div>
                            <p class="error-text" *ngIf="errorMsg">{{errorMsg}}</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>