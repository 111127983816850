import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { LoginModalComponent } from '../../components/modal/login/login-modal.component';
import { VideoModalComponent } from '../../components/modal/video-modal/video-modal.component';
import { SignupModalComponent } from '../../components/modal/signup/signup-modal.component';
import { DataService } from '../../services/data.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @ViewChild("loginModal") LoginModal: LoginModalComponent;
  @ViewChild("signupModal") SignupModal: SignupModalComponent;
  
  public stick: boolean = false;
  public userInfo;

  constructor(private dataService: DataService, private cookie: CookieService) { }

  ngOnInit(): void {
    this.dataService.currentUser.subscribe((user)=>{
      this.userInfo = user;
    })

    let user = this.cookie.get("_userinfo");
    if(user) {
      user = JSON.parse(user);        
      this.dataService.updateUser(user);
    }
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  openLoginModal() {
    this.LoginModal.openModal();
  }

  openSignupModal() {
    this.SignupModal.openModal();
  }

  logout() {
    this.cookie.delete("_userinfo");
    this.dataService.updateUser(null);
  }

}
