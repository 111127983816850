<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our Gem Store</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: +91 63646 71972</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
              <li class="mobile-search">
                <a><i class="fa fa-search" aria-hidden="true"></i>Search</a>
              </li>
            <li class="mobile-cart pr-0">
              <div>
                <a [routerLink]="['/shop/cart']">
                  <i class="fa fa-shopping-cart" aria-hidden="true"></i>My Bag
                </a>
              </div>
              <!-- <span class="cart_qty_cls" ngIf>0</span> -->
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li>
            <li class="mobile-account" *ngIf="!userInfo">
              <a (click)="openLoginModal()"><i class="fa fa-user" aria-hidden="true"></i> My Account</a>
            </li>
            <li class="onhover-dropdown mobile-account" *ngIf="userInfo">
              <i class="fa fa-user" aria-hidden="true"></i> {{userInfo.name}}
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="en">
                    My Orders
                  </a>
                </li>
                <li>
                  <a data-lng="es" (click)="logout()">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!-- <app-left-menu></app-left-menu> -->
            <div class="brand-logo">
              <a routerLink="/home/gems" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid img-logo" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <!-- <div>
              <app-settings></app-settings>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<app-login-modal #loginModal (showSignupModal)="openSignupModal()"></app-login-modal>
<app-signup-modal #signupModal (showLoginModal)="openLoginModal()"></app-signup-modal>