import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CustomerService } from '../../services/customer.service';

import * as $ from 'jquery'

declare global {
  interface Window {
    google: any;
  }
}

declare var google: any;

@Component({
  selector: 'app-google-signin',
  templateUrl: './google-signin.component.html',
  styleUrl: './google-signin.component.scss'
})

export class GoogleSigninComponent implements OnInit {
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>();

  constructor(private service: CustomerService) {
    
  }

  ngOnInit(): void {
    google.accounts.id.initialize({
      client_id: '518124212736-dd44gh7con42defb3tnkdm53vgselb42.apps.googleusercontent.com',
      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }  // customization attributes
    );
    google.accounts.id.prompt();

    // document.querySelector('#hiddenGoogleToken').addEventListener('change', () => {
    //   debugger;
    // }
    // );
  }

  handleCredentialResponse(response: any) {
    console.log("Encoded JWT ID token: " + response.credential);

    // this.loginWithGoogle.emit(response);

    $("#hiddenGoogleToken").val(response.credential).change();
  }

  changeGoogleToken = () => {
    debugger;
  }

  createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200',
    });

    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      'div[role=button]'
    ) as HTMLElement;

    return {
      click: () => {
        googleLoginWrapperButton?.click();
      },
    };
  };

  handleGoogleLogin() {
    this.loginWithGoogle.emit(this.createFakeGoogleWrapper());
  }
}
