import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  user = new BehaviorSubject(null);

  currentUser = this.user.asObservable();

  updateUser(user) {
    this.user.next(user);
  }
}
